<template>
    <div>
        <div class="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
            <router-link to="/settings" class="d-inline-block mt-2"><i class="mico-feather-12 font-sm text-white"></i></router-link>
            <h4 class="font-xs text-white fw-600 ms-4 mb-0 mt-2">Account Details</h4>
        </div>
        <div class="card-body p-lg-5 p-4 w-100 border-0">
            <div class="row justify-content-center">
                <div class="col-lg-4 text-center">
                   <figure class="pro-img">
                            <Loader :stat='loader' background="transparent" spinner="var(--primary-color)"></Loader>
                            <label for="userdp-file"> 
                                <img :src="userData.userDp?userData.userDp:'https://d15bmhsw4m27if.cloudfront.net/public/dp.jpg'">
                                <i class="mico-feather-13"></i>
                            </label>
                            <input type="file" class="userdp-file" id="userdp-file" @change="changeDp">
                    </figure>
                    <h2 class="fw-700 font-sm text-grey-900 mt-3">{{userData.fullName}}</h2>
                    <h4 class="text-grey-500 fw-500 mb-3 font-xsss mb-4">{{userData.username}}</h4>    
                </div>
            </div>
            <div class="row profile-edit">
                <div class="col-lg-10">
                    <Form  id="edit-profile-info" method="post" @submit="updateprofile">
                        <div class="mb-3">
                            <label for="fullName" class="form-label">Fullname</label>
                            <Field ref="fullName"  type="text" class="form-control" id="fullName" name="fullName" placeholder="Full name" v-model="userData.fullName">
                            </Field>
                            <ErrorMessage name="useremail" />
                        </div>
                        <div class="mb-3">
                            <label for="username" class="form-label">Username</label>
                            <Field ref="username"  type="email" class="form-control" id="username" name="username" placeholder="Enter username"  v-model="userData.username" >
                            </Field>
                            <ErrorMessage name="useremail" />
                        </div>
                        <div class="mb-3">
                            <label for="about" class="form-label">About</label>
                            <Field rows="5" maxlength="320" class="form-control bg-greylight" id="about" as="textarea" name="about" placeholder="Tell the world who you are!" v-model="userData.bio" ></Field>
                        </div>
                        <div class="mb-3"> <p>{{profileEditstatus}}</p></div>
                        <div class="mb-3"><button class="btn" type="submit">Update Profile</button></div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Form, Field ,ErrorMessage} from 'vee-validate';
import axios from '@/plugins/axios'
import noty from 'noty'

export default {
    components: { Form, Field, ErrorMessage},
     data () {
        return {
            userData:{},
            profileEditstatus:'',
            loader:false
        }
    },
    methods: {
        getUserData: function () {
            var self = this
            axios.get('/api/user/profile').then(response => {
                self.userData = response.data.data;
                self.getUserOtp();
            }).catch(error => {
                
            })
        },
        updateprofile:function(values){
        //     console.log(p)
        // console.log(p.replace(/<\/?[^>]+(>|$)/g, ""))
           // return false
            this.profileEditstatus ="";
            if( !(values.fullName || values.about || values.username)) return this.profileEditstatus = 'Fields cant be empty!'
            let self= this
            document.querySelector('#edit-profile-info .btn').disabled=true;
            axios.put('/api/user/profile', {
                fullName:values.fullName,
                username: values.username,
                bio: values.about
            }).then(async response => {
                document.querySelector('#edit-profile-info .btn').disabled=false;
                await self.$store.dispatch('userData', { fullName : self.userData.fullName , username : self.userData.username })

                new noty({text: 'Profile updated' , type:"success", layout:"bottomRight" , timeout:1000}).show();
            }).catch(error=>{
                self.profileEditstatus =error.response.data.message
                new noty({text: error.response.data.message , type:"error", layout:"bottomRight" , timeout:1000}).show();
                document.querySelector('#edit-profile-info .btn').disabled=false;
            })
        },
        changeDp:function(){
            var formData = new FormData();
            var imagefile = document.querySelector('#userdp-file');
            var self = this
            self.loader=true
            formData.append("attachment", imagefile.files[0]);
            axios.put('/api/user/updatedp', formData, {   headers: {'Content-Type': 'multipart/form-data'}}).then(async resp => {
                new noty({text: 'Dp updated' , type:"success", layout:"bottomRight" , timeout:1000}).show();      
                self.userData.userDp = resp.data.data.userDp;
                await self.$store.dispatch('userData', { userDp : self.userData.userDp  })
                self.loader=false
            }).catch(error=>{
                 new noty({text: 'Dp update fail' , type:"error", layout:"bottomRight" , timeout:1000}).show();
                  self.loader=false
            })
            //
        }
    },
    created: function () {
        this.getUserData();
         
    },
}
</script>